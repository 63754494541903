<template>
  <div class="box">
    <h2>实例demo</h2>
    <Field
      v-model="keySecret"
      name="密钥"
      label="密钥"
      placeholder="密钥"
    ></Field>
    <br />
    <Button type="primary" @click="handleId">生成onetimeId</Button>
    <br />
    <h2>Input 输入</h2>
    <Field
      v-model="str"
      rows="4"
      autosize
      label="Input 输入"
      type="textarea"
      placeholder="请输入"
    />
    <div class="btn">
      <Button type="primary" @click="handleEncrypt">encrypt</Button>
      <Button type="success" @click="handleDecrypt">decrypt</Button>
    </div>

    <h2>Output 输出</h2>
    <Field
      v-model="outStr"
      rows="4"
      autosize
      disabled
      label="Output 输出"
      type="textarea"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import request from "@/utils/request";
import { Field, Button } from "vant";

const {
  base64_to_bytes,
  bytes_to_base64,
  string_to_bytes,
  bytes_to_string,
  AES_GCM,
} = asmCrypto;
// 密钥
let keySecret = ref("40CaFFH5Ub61QUsWz9meFzounyySLoMzizlXVNoJjdc=");

// 输入
let str = ref("");

// 输出
let outStr = ref("");

const base64_req_iv = "h/yB5x7CQByyq5JR";

const req_iv = base64_to_bytes(base64_req_iv);
// 加密
const encrypt = (key, input) => {
  const in_bytes = string_to_bytes(input);
  const aes = new AES_GCM(key, req_iv, undefined, 16);
  const en_data = aes.encrypt(in_bytes);
  return base64_req_iv + bytes_to_base64(en_data);
};
// 解密
const decrypt = (key, input) => {
  const base64_resp_iv = input.substring(0, 16);
  const base64_data = input.substring(16);

  const resp_iv = base64_to_bytes(base64_resp_iv);
  const data = base64_to_bytes(base64_data);
  const aes = new AES_GCM(key, resp_iv, undefined, 16);
  return bytes_to_string(aes.decrypt(data));
};

// 加密触发
const handleEncrypt = () => {
  const key = base64_to_bytes(keySecret.value);
  outStr.value = encrypt(key, str.value);
};

// 解密触发
const handleDecrypt = () => {
  const key = base64_to_bytes(keySecret.value);
  outStr.value = decrypt(key, str.value);
};

// 生成随机数
const randomString = (e) => {
  e = e || 32;
  const t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length;
  let n = "";
  for (let i = 0; i < e; i++) {
    n += t.charAt(Math.floor(Math.random() * a));
  }
  return n;
};

// 生成onetimeId
const handleId = () => {
  const key = base64_to_bytes(keySecret.value);
  const data = {
    txnRefId: randomString(),
    customerName: "HouTest",
    customerMobile: "9123456780",
    amount: 3.0,
    productName: "Maintenance",
    paymentRef: "",
    resultCallbackUrl: "",
    jumpUrlAfterPayment: "https://www.baidu.com",
  };
  request({
    url: "/h5/openapi/v1/pg-gateway/collection",
    method: "post",
    data: encrypt(key, JSON.stringify(data)),
    headers: {
      "X-MERCHANT-PARTNER-ID": "MPA01L000001",
      "Content-Type": "text/plain",
    },
  }).then((code) => {
    str.value = code;
  });
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  .btn {
    display: flex;
    justify-content: space-between;
    margin: 20px 50px;
    button {
      width: 48%;
    }
  }
}
</style>
